/* eslint-disable */
import { toRaw } from '@vue/reactivity';

export const createStorageShiftsTime = (shiftsCount, shiftStartTime, shiftsName = []) => {
  let shiftsTime = [],
    dayDuration = 24;

  if (shiftsCount && shiftStartTime) {
    let shiftDuration = dayDuration / parseInt(shiftsCount, 10),
      startTime = parseInt(shiftStartTime.substr(0, 2), 10);

    for (let i = 0; i < shiftsCount; i++) {
      let endTime = startTime + shiftDuration;

      if (startTime > 24) startTime = startTime - 24;
      if (endTime > 24) endTime = endTime - 24;
      if (startTime === 24) startTime = 0;
      if (endTime === 24) endTime = 0;

      shiftsTime.push({
        name: shiftsName[i] ?? '',
        startTime: ('0' + startTime.toString()).slice(-2) + ':00',
        endTime: ('0' + endTime.toString()).slice(-2) + ':00',
      });

      startTime = endTime;
    }
  }

  return shiftsTime;
};

export const createStorageWorkTime = (days, workingShifts) => {
  let workTimes = [];

  if (days && workingShifts) {
    days.forEach((item) => {
      let day = {
        weekDayName: item,
        workingShifts: workingShifts,
      };

      workTimes.push(day);
    });
  }

  return workTimes;
};

export const getIntersections = ([...arrs]) => {
  let result = [];

  let data = arrs.shift();

  for (let item of data) {
    if (inArrays(item, arrs)) {
      result.push(item);
    }
  }

  return result;
};

export const inArrays = (item, arrs) => {
  for (let arr of arrs) {
    if (!inArray(item, arr)) {
      return false;
    }
  }

  return true;
};

export const inArray = (item, arr) => {
  return arr.indexOf(item) !== -1;
};

export const getCountPerShift = (restrictionCount, workingShiftsCount, workingDaysCount) => {
  if (workingDaysCount < 1) {
    return;
  }

  let result = null;

  if (restrictionCount) {
    const allPeriodShifts = workingShiftsCount * workingDaysCount;

    result = Math.floor(restrictionCount / allPeriodShifts);
  }

  return result;
};

export const getPercentValue = (value) => {
  return `${parseInt(value * 100)}%`;
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;

  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatTimeFromDate = (date) => {
  return new Date(date).toLocaleString('ru', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const formatTime = (time) => {
  let result = null;

  if (time !== null) {
    result = time.slice(0, -3);
  }

  return result;
};

export const getDayShortName = (dayIds) => {
  if (!dayIds.length) {
    return;
  }

  let result = '',
    tempDays = [],
    days = {
      1: 'Пн',
      2: 'Вт',
      3: 'Ср',
      4: 'Чт',
      5: 'Пт',
      6: 'Сб',
      7: 'Вс',
    };

  dayIds.forEach((dayId) => {
    tempDays.push(days[dayId]);
  });

  if (tempDays.length) {
    result = tempDays.join(', ');
  }

  return result;
};

export const getGroupedRangesByTime = (srcArray) => {
  let groupedRanges = [];

  toRaw(srcArray).forEach((elem) => {
    const name = (elem.startAt.toString() + elem.endAt.toString()).replace(/[\s:.,%]/g, '');
    const group = groupedRanges.find((group) => group.name === name);

    if (!group) {
      groupedRanges.push({
        name,
        startAt: elem.startAt,
        endAt: elem.endAt,
        dayOfWeekIds: [elem.dayOfWeek?.id],
      });
    } else {
      group.dayOfWeekIds.push(elem.dayOfWeek?.id);
    }
  });

  if (groupedRanges.length) {
    groupedRanges.map((group) => {
      group.dayOfWeekIds.sort((a, b) => a - b);

      return group;
    });
  }

  return groupedRanges;
};

export const getNormalTextFormat = (number, wordsArr) => {
  let currentWord = '';

  number = Math.abs(number);

  if (Number.isInteger(number)) {
    let options = [2, 0, 1, 1, 1, 2];
    currentWord = wordsArr[number % 100 > 4 && number % 100 < 20 ? 2 : options[number % 10 < 5 ? number % 10 : 5]];
  }

  return currentWord;
};

export const checkPasswordRating = (data) => {
  let result = {
    type: '',
    text: '',
  };

  if (data.length) {
    const password = data,
      smallLetters = 'qwertyuiopasdfghjklzxcvbnm',
      bigLetters = 'QWERTYUIOPLKJHGFDSAZXCVBNM',
      cyrillicLetters = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ',
      digits = '0123456789',
      specials = '!@#$%^&*()_-+=|/.,:;[]{}';

    let hasSmallLetters = false,
      hasBigLetters = false,
      hasDigits = false,
      hasSpecials = false,
      hasCyrillicLetters = false;

    let rating = 0,
      ratingType = '',
      ratingText = '';

    for (let i = 0; i < password.length; i++) {
      if (!hasSmallLetters && smallLetters.indexOf(password[i]) !== -1) hasSmallLetters = true;
      else if (!hasBigLetters && bigLetters.indexOf(password[i]) !== -1) hasBigLetters = true;
      else if (!hasDigits && digits.indexOf(password[i]) !== -1) hasDigits = true;
      else if (!hasSpecials && specials.indexOf(password[i]) !== -1) hasSpecials = true;
      else if (!hasCyrillicLetters && cyrillicLetters.indexOf(password[i]) !== -1) hasCyrillicLetters = true;
    }

    if (hasSmallLetters) rating++;
    if (hasBigLetters) rating++;
    if (hasDigits) rating++;
    if (hasSpecials) rating++;

    if (hasCyrillicLetters) {
      ratingType = 'low';
      ratingText = 'Кириллические символы запрещены';
    } else {
      if (password.length < 14 && (rating === 1 || rating === 2)) {
        ratingType = 'low';
        ratingText = 'Слабый и короткий пароль, минимум 14 символов';
      } else if (password.length < 14 && rating === 3) {
        ratingType = 'low';
        ratingText = 'Хороший пароль, но мало символов, минимум 14 символов';
      } else if (password.length < 14 && rating === 4) {
        ratingType = 'low';
        ratingText = 'Надёжный пароль, но мало символов, минимум 14 символов';
      } else if (password.length >= 14 && (rating === 1 || rating === 2)) {
        ratingType = 'low';
        ratingText = 'Слабый пароль';
      } else if (password.length >= 14 && rating === 3) {
        ratingType = 'medium';
        ratingText = 'Хороший пароль';
      } else if (password.length >= 14 && rating === 4) {
        ratingType = 'hard';
        ratingText = 'Надёжный пароль';
      }
    }

    result = {
      type: ratingType,
      text: ratingText,
    };
  }

  return result;
};

export const getDenyPrivilegeObject = () => {
  return {
    read: 'deny',
    create: 'deny',
    update: 'deny',
    delete: 'deny',
  };
};

export const getArrayObjectsIds = (array) => {
  let result = [];

  if (array.length) {
    array.forEach((item) => {
      result.push(item.id);
    });
  }

  return result;
};

export const getUnique = (array) => {
  return array.filter((el, ind) => ind === array.indexOf(el));
};

export const getErrorMessage = (errorData, fields = []) => {
  let errorMessage = errorData?.message,
    errorDetails = errorData?.error?.details;

  if (errorDetails && errorDetails.length) {
    if (errorDetails.length > 5) {
      const limitedArray = errorDetails.slice(0, 5);
      limitedArray.push('...');

      errorDetails = limitedArray;
    }

    errorMessage += '<div class="flex column mt-16">';

    errorDetails.forEach((errorItem) => {
      let index = errorItem.indexOf(':');

      if (index !== -1) {
        let errorItemField = errorItem.slice(0, index + 1);
        let errorItemText = errorItem.slice(index + 1);

        if (fields.filter((item) => item.name === errorItemField).length) {
          errorItemField = fields.filter((item) => item.name === errorItemField)[0].title;
        }

        errorMessage += `<div class="size-16">${errorItemField} <span class="text-normal">${errorItemText}</span></div>`;
      } else {
        errorMessage += `<div class="size-16"><span class="text-normal">${errorItem}</span></div>`;
      }
    });

    errorMessage += '</div>';
  }

  return errorMessage;
};

export const getNormalDateTimeFormat = (dateTime) => {
  if (!dateTime) {
    return;
  }

  let result = dateTime;

  if (dateTime.split(' ').length > 1) {
    result = dateTime.split(' ').join('T');
  }

  return result;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailRegex.test(email);
}

export const getFormattedFullDate = (date) => {
  return new Date(date).toLocaleString('ru', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
}

export const getCorrectActionName = (val) => {
  let result;
  switch (val) {
    case 'create':
      result = 'Создание';
      break;
    case 'update':
      result = 'Обновление';
      break;
    case 'delete':
      result = 'Удаление';
      break;
    default:
      result = val;
      break;
  }
  return result;
}

export const getFieldNameByCode = (fieldCode) => {
  let result;

  switch (fieldCode) {
    case 'id':
      result = 'Id';
      break;
    case 'slot':
      result = 'Слот';
      break;
    case 'partner':
      result = 'Партнер';
      break;
    case 'planCount':
      result = 'Плановое кол-во единиц';
      break;
    case 'inbound':
      result = 'Маршрут Inbound';
      break;
    case 'outbound':
      result = 'Маршрут Outbound';
      break;
    case 'status':
      result = 'Статус';
      break;
    case 'boxCount':
      result = 'Плановое кол-во коробов';
      break;
    case 'carSize':
      result = 'Объем ТС';
      break;
    case 'pallet':
      result = 'Паллетирование';
      break;
    case 'driverName':
      result = 'Водитель';
      break;
    case 'carNumber':
      result = 'Номер авто';
      break;
    case 'truck':
      result = 'Тип машины';
      break;
    case 'driverPhone':
      result = 'Номер телефона водителя';
      break;
    case 'driverDocument':
      result = 'Паспортные данные водителя';
      break;
    case 'edo':
      result = 'ЭДО';
      break;
    case 'actualArrivalTime':
      result = 'Фактическое время прибытия';
      break;
    case 'plannedZr':
      result = 'Плановое ZR';
      break;
    case 'plannedRepack':
      result = 'Плановая переупаковка';
      break;
    case 'lamodaCar':
      result = 'Заказ машины';
      break;
    case 'cancelReason':
      result = 'Причина отмены брони';
      break;
    case 'gate':
      result = 'Ворота';
      break;
    case 'gateCallTime':
      result = 'Дата и время вызова на ворота';
      break;
    case 'parkingEntryTime':
      result = 'Дата и время отправки машины на парковку';
      break;
    case 'stampPresence':
      result = 'Наличие пломбы';
      break;
    case 'palletCount':
      result = 'Плановое кол-во паллет';
      break;
    case 'atWheelsInspection':
      result = 'Проверка с колес';
      break;
    case 'documentsReceivedAt':
      result = 'Дата и время принятия документов';
      break;
    case 'loadStartedAt':
      result = 'Дата и время начала выгрузки/отгрузки';
      break;
    case 'loadFinishedAt':
      result = 'Дата и время окончания выгрузки/отгрузки';
      break;
    case 'lateMinutes':
      result = 'Время опоздания';
      break;
    case 'carrier':
      result = 'Перевозчик';
      break;
    case 'isInterrogation':
      result = 'Заявка на дозапрос слота';
      break;
    case 'isAutoGoesToLoad':
      result = 'Авто едет на погрузку / После разгрузки';
      break;
    case 'numberOfPallets':
      result = 'Кол-во паллет (факт)';
      break;
    case 'numberOfBoxes':
      result = 'Кол-во коробов (факт)';
      break;
    case 'isSendDelayDidNotAffectTheService':
      result = 'Задержка отправки не повлияла на сервис';
      break;
    case 'commentOnNotShippedOnTime':
      result = 'Комментарий к «Не отгружено вовремя»';
      break;
    case 'unloadedPlasticBoards':
      result = 'Загружено / Выгружено пластиковых бортов';
      break;
    case 'parishList':
      result = 'Список прихода на момент прибытия';
      break;
    case 'reasonForNotUnloadOnTime':
      result = 'Причина, почему не принято вовремя';
      break;
    case 'reasonsForNotShippingOnTime':
      result = 'Причина, если не отгружено вовремя';
      break;
    case 'numberOfPackages':
      result = 'Кол-во грузовых мест (кол-во паллет, если на паллетах, или кол-во коробов)';
      break;
    case 'BookingComment':
      result = 'Комментарий';
      break;
    case 'BookingDocument':
      result = 'Документ';
      break;
    case 'documentStatus':
      result = 'Статус документа';
      break;
    case 'BookingTag':
      result = 'Тег';
      break;
    case 'SmiOt':
      result = 'SMI/OT';
      break;
    case 'volumeM3':
      result = 'Объем м3';
      break;
    case 'manualSupplyData':
      result = 'Поставки, введённые вручную';
      break;
    case 'finalCount':
      result = 'Заявлено единиц (факт)';
      break;
    case 'latestSlot':
      result = 'Финальный слот';
      break;
    case 'externalId':
      result = 'Технический ID CRM';
      break;
    case 'Booking':
      result = 'Бронь';
      break;case 'comment':
      result = 'Комментарий';
      break;
    case '*':
      result = 'Создана запись';
      break;
    default:
      result = fieldCode;
      break;
  }

  return result;
};

export const checkFieldIsDate = (field, value) => {
  if (!value) {
    return;
  }

  let result,
    fieldList = [
      'actualArrivalTime',
      'gateCallTime',
      'parkingEntryTime',
      'documentsReceivedAt',
      'loadStartedAt',
      'loadFinishedAt',
      'assemblyDate',
      'startAt',
      'endAt',
      'createdAt',
      'updatedAt'
    ];

  result = fieldList.includes(field);


  return result;
};
